import type { BreadcrumbItemProps } from 'components/elements/breadcrumbs'
import type { RouteConfig } from 'utils/ts/shared-types'

import { roleEnum } from 'hooks/admin-user-types'

import { CORE_DATA_ROUTES } from '../config'

export const PARTNER_LABEL = 'Partner'

export const CORE_DATA_PARTNER_ROUTES: RouteConfig<'create' | 'edit'> = {
  create: {
    label: `Create new ${PARTNER_LABEL}`,
    permissions: roleEnum.editor,
    uri: () => '/core-data/partners/new',
  },
  edit: {
    label: `Edit ${PARTNER_LABEL}`,
    permissions: roleEnum.editor,
    uri: (id: string) => `/core-data/partners/${encodeURIComponent(id)}`,
  },
  index: {
    label: 'Partners',
    permissions: roleEnum.editor,
    uri: () => '/core-data/partners',
  },
}

export const CORE_PARTNER_BREADCRUMBS: BreadcrumbItemProps[] = [
  { href: CORE_DATA_ROUTES.index.uri(), title: CORE_DATA_ROUTES.index.label },
  { href: CORE_DATA_PARTNER_ROUTES.index.uri(), title: CORE_DATA_PARTNER_ROUTES.index.label },
]
