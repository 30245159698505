import type { RouteConfig } from 'utils/ts/shared-types'

import { roleEnum } from 'hooks/admin-user-types'

export const ASSET_DATA_ALBUM_ROUTES: RouteConfig<'edit'> = {
  edit: {
    label: 'Album Info',
    permissions: roleEnum.editor,
    uri: (albumId: string) => `/asset-data/albums/${encodeURIComponent(albumId)}`,
  },
  index: {
    label: 'Albums',
    permissions: roleEnum.editor,
    uri: () => '/asset-data/albums',
  },
}
