import type { RouteConfig } from 'utils/ts/shared-types'

import { roleEnum } from 'hooks/admin-user-types'

export const PLAYOUT_SCHEDULER_ROUTES: RouteConfig = {
  index: {
    label: 'Playout Scheduler',

    permissions: roleEnum.stream_engineer,
    uri: () => '/livestreams/playout-scheduler',
  },
}
