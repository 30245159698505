import type { RouteConfig } from 'utils/ts/shared-types'

import { roleEnum } from 'hooks/admin-user-types'

export const LIVE_CONCERT_SETTINGS_ROUTES: RouteConfig = {
  index: {
    label: 'Live Concert Settings',

    permissions: roleEnum.stream_engineer,
    uri: () => '/livestreams/live-concert-settings',
  },
}
