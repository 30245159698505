import type { BreadcrumbItemProps } from 'components/elements/breadcrumbs'
import type { RouteConfig } from 'utils/ts/shared-types'

import { roleEnum } from 'hooks/admin-user-types'

import { ASSET_DATA_ROUTES } from '../config'

export const LIVE_CONCERT_LABEL = 'Live-Concert'

export const ASSET_DATA_LIVE_CONCERT_ROUTES: RouteConfig<
  'create' | 'createPerformanceWork' | 'edit' | 'editPerformanceWork'
> = {
  create: {
    label: `Create new ${LIVE_CONCERT_LABEL}`,
    permissions: roleEnum.editor,
    uri: () => '/asset-data/live-concerts/new',
  },
  createPerformanceWork: {
    label: `Create ${LIVE_CONCERT_LABEL} Performance Work`,
    permissions: roleEnum.editor,
    uri: (concertId: string) => `/asset-data/live-concerts/${encodeURIComponent(concertId)}/performance-works/new`,
  },
  edit: {
    label: `Edit ${LIVE_CONCERT_LABEL}`,
    permissions: roleEnum.editor,
    uri: (concertId: string) => `/asset-data/live-concerts/${encodeURIComponent(concertId)}`,
  },
  editPerformanceWork: {
    label: 'Edit Performance Work',
    permissions: roleEnum.editor,
    uri: (concertId: string, workId: string) =>
      `/asset-data/live-concerts/${encodeURIComponent(concertId)}/performance-works/${encodeURIComponent(workId)}`,
  },
  index: {
    label: 'Live-Concerts',
    permissions: roleEnum.editor,
    uri: () => '/asset-data/live-concerts',
  },
}

export const LIVE_CONCERT_BREADCRUMBS: BreadcrumbItemProps[] = [
  { href: ASSET_DATA_ROUTES.index.uri(), title: ASSET_DATA_ROUTES.index.label },
  { href: ASSET_DATA_LIVE_CONCERT_ROUTES.index.uri(), title: ASSET_DATA_LIVE_CONCERT_ROUTES.index.label },
]
